<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="form-group">
        <label for="">Nombre</label>
        <app-input-string v-model="name"></app-input-string>
      </div>
      <div class="form-group">
        <label for="">Entidad</label>
        <select-entity v-model="entity_id"></select-entity>
      </div>
      <div class="form-group">
        <label for="">Area</label>
        <select-area v-model="area_id"></select-area>
      </div>
      <div class="form-group">
        <label for="">Razon Salida</label>
        <input type="number" step="1" v-model="pay_reason_id" class="form-control">
      </div>
      <div class="form-group">
        <label for="">Imagen de Perfil</label>
        <form ref="elFormInput">
          <input type="file" ref="elFile" accept="image/*" />
        </form>
      </div>
      <div class="form-group">
        <label for="">Remuneracion fija</label>
        <app-input-money
          :quantity.sync="payment"
          :moneyCode.sync="money_code"
        ></app-input-money>
      </div>
      <app-button-submit @click="save()"></app-button-submit>
    </div>
    <div class="col-lg-6" v-if="id">
      <h4>Especialidades</h4>
      <specialties :employeeId="id"></specialties>
    </div>
  </div>
</template>
<script>
import selectEntity from "../entitys/Select";
import selectArea from "../areas/Select";
import { AdminService } from "../AdminService";
import { GeneralService } from "../../general-module/GeneralService";
import specialties from "./specialties/List";

export default {
  components: {
    selectEntity,
    selectArea,
    specialties
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    id: null,
    name: null,
    entity_id: null,
    area_id: null,
    client_id: null,
    store_id: null,
    avatar_file_id: null,
    money_code: 1,
    payment: 0,
    pay_reason_id: null,
    // r_user_id: null,
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reload() {
      this.id = null;
      this.name = null;
      this.entity_id = null;
      this.area_id = null;
      this.client_id = null;
      this.store_id = null;
      this.avatar_file_id = null;
      this.money_code = 1;
      this.pay_reason_id = null;
      this.payment = 0;
      this.$refs.elFormInput.reset();
    },
    load(reg) {
      this.id = reg.id;
      this.name = reg.name;
      this.entity_id = reg.entity_id;
      this.area_id = reg.area_id;
      this.client_id = reg.client_id;
      this.store_id = reg.store_id;
      this.avatar_file_id = reg.avatar_file_id;
      this.money_code = reg.money_code;
      this.payment = reg.payment;
      this.pay_reason_id = reg.pay_reason_id;
      // this.r_user_id = reg.r_user_id;
    },
    async save() {
      if (this.$refs.elFile.files && this.$refs.elFile.files.length > 0) {
        var list = await GeneralService.uploadImages(this.$refs.elFile.files);
        this.avatar_file_id = list[0];
      }
      AdminService.saveEmployee(this.$data).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style></style>
